import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import { getToken } from '../../services/auth';

import { Creators as DocumentoActions } from '../ducks/documentos';
import { Creators as AnaliseActions } from '../ducks/analise';
import { Creators as MensagemActions } from '../ducks/mensagens';

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getCurrentUserId = (state) => state.usuarios.auth.id;
export const getCompaniesRules = (state) => state.empresas.regras;

export function* createDocumento({ payload }) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.post, 'documentos', payload, headerParams);

    yield put(DocumentoActions.createSuccess());
    toastr.success(
      'Sucesso',
      'Você será notificado assim que o processo de indexação estiver concluído.'
    );
  } catch (error) {
    toastr.error('Ops', 'Não foi possível criar o documento.');
  }
}

export function* getDocumento(action) {
  const organization = yield select(getOrganization);
  yield put(MensagemActions.listInitialize());

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    if (action.payload) {
      const { data } = yield call(
        api.get,
        `/documento/${action.payload}`,
        headersParams
      );

      yield put(DocumentoActions.getDocumentoSuccess(data));
    } else {
      const data = {};

      yield put(DocumentoActions.getDocumentoSuccess(data));
    }
  } catch (error) {
    toastr.error('Ops', 'Não foi possível retornar o documento.');
  }
}

export function* getInfoFromOneDoc(action) {
  const organization = yield select(getOrganization);
  yield put(MensagemActions.listInitialize());

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };
    if (action.payload) {
      const { data } = yield call(
        api.get,
        `/documento/${action.payload}`,
        headersParams
      );

      yield put(DocumentoActions.getInfoFromOneDocumentSuccess(data));
    } else {
      const data = {};

      yield put(DocumentoActions.getInfoFromOneDocumentSuccess(data));
    }
  } catch (error) {
    toastr.error('Ops', 'Não foi possível retornar o documento.');
  }
}

export function* listDocumentosByEmpresa({ payload }) {
  const organization = yield select(getOrganization);

  const { empresa, page, limit, sort } = payload;

  const fields =
    'empresa._id,empresa.razaoSocial,tipoDocumento,indexadores,userAcao,createdAt';

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort, fields },
    };

    const { data } = yield call(
      api.get,
      `/empresas/${empresa}/documentos`,
      headersParams
    );

    const { docs, total } = data;

    yield put(DocumentoActions.listSuccessByEmpresa({ docs, total }));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar documentos por etapa.');
  }
}

export function* listDocumentosByEtapas({ payload }) {
  const organization = yield select(getOrganization);

  let {
    fluxo,
    fila,
    etapas,
    page,
    limit,
    sort,
    fields,
    values,
    endEvent,
    empresas,
  } = payload;

  etapas = limit ? etapas : payload;

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: { page, limit, sort, fields },
    };

    const { data } = yield call(
      api.post,
      `/analise/docs/etapa`,
      { empresas, etapas, fila, fluxo, values, endEvent },
      headersParams
    );

    yield put(DocumentoActions.listSuccessByEtapas(data));
    yield put(AnaliseActions.setCurrentFilaSuccess(fila));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar documentos por etapa.');
  }
}

export function* listDocumentosByEtapaRelatorio(action) {
  const organization = yield select(getOrganization);

  let body = [[action.payload]];

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `/analise/docs/etapa`,
      body,
      headersParams
    );

    yield put(DocumentoActions.listSuccessByEtapaRelatorio(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível listar documentos por etapa.');
  }
}

export function* exportDocumentosToCSV(action) {
  try {
    const headersParams = {
      headers: { Authorization: getToken() },
    };

    yield call(api.post, `/csv`, action.payload, headersParams);
  } catch (error) {
    toastr.error('Ops', 'Não foi possível exportar os documentos.');
  }
}

export function* goSearchDocumentos(action) {
  yield put(push(`/documentos/pesquisa`));

  const organization = yield select(getOrganization);

  try {
    const { ids, params, justOne } = action.payload;

    if (justOne) {
      delete params.searchText;
    }

    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: {
        ...params,
        page: !params.page || params.page === 0 ? 1 : params.page,
      },
    };

    if (action.payload) {
      const { data } = yield call(
        api.post,
        `/documentos/search`,
        { ids },
        headersParams
      );

      yield put(DocumentoActions.advancedSearchResultSuccess(data));
    } else {
      yield put(DocumentoActions.advancedSearchResultSuccess([]));

      yield put(push(`/documentos/pesquisa`));
    }
  } catch (error) {
    toastr.error('Ops', 'Não foi possível pesquisar os documentos.');
  }
}

export function* autocompleteDocumentos(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    if (action.payload.q) {
      const { data } = yield call(
        api.get,
        `/documentos/search?q=${action.payload.q}`,
        headersParams
      );

      yield put(DocumentoActions.autoCompleteSuccess(data));
    } else {
      yield put(DocumentoActions.autoCompleteSuccess([]));
    }
  } catch (error) {
    toastr.error('Ops', 'Não foi possível pesquisar os documentos.');
  }
}

export function* searchEsById(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/documentos/searchbyid?id=${action.payload}`,
      headersParams
    );

    yield put(DocumentoActions.searchEsByIdSuccess(data));
  } catch (error) { }
}

//Atualiza Indexadores.
export function* updateIndexadores(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { reloadDoc, id } = action.payload;

    const { data } = yield call(
      api.post,
      `documento/indexadores/update`,
      action.payload,
      headersParams
    );

    if (reloadDoc) {
      yield put(DocumentoActions.getDocumentoRequest(id));
    }

    yield put(DocumentoActions.updateDocIndexSuccess(data));

    toastr.success('Sucesso', 'O Processo foi atualizado.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível atualizar os indexadores.');
  }
}

export function* updateTypeImageArchieve(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { reloadDoc, id, documentId } = action.payload;

    const { data } = yield call(
      api.post,
      `documento/imagem/update/tipoimagem`,
      action.payload,
      headersParams
    );

    if (reloadDoc) {
      //yield put(DocumentoActions.getDocumentoRequest(id));

      const { data: documentInfos } = yield call(
        api.get,
        `/documento/${documentId}`,
        headersParams
      );

      yield put(DocumentoActions.getDocumentoSuccess(documentInfos));
    }

    yield put(DocumentoActions.updateDocIndexImgSuccess(data));

    toastr.success(
      'Sucesso',
      'O tipo de imagem do arquivo foi atualizado com sucesso'
    );
  } catch (error) {
    toastr.error('Ops', 'Não foi possível alterar o tipo de imagem do arquivo');
  }
}

export function* updateIndexadoresImage(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { reloadDoc, documentId } = action.payload;

    const { data } = yield call(
      api.post,
      `documento/imagem/indexadores/update`,
      action.payload,
      headersParams
    );

    if (reloadDoc) {
      yield put(DocumentoActions.getDocumentoRequest(documentId));
    }

    yield put(DocumentoActions.updateDocIndexImgSuccess(data));

    toastr.success('Sucesso', 'Os indexadores de imagem foram atualizados.');
  } catch (error) {
    toastr.error(
      'Ops',
      'Não foi possível atualizar os indexadores de imagem foram atualizados.'
    );
  }
}

export function* confirmacaoPresenca({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `concilig/mark-presence`,
      payload,
      headersParams
    );

    toastr.success("Presença registrada com sucesso.");

    yield put(DocumentoActions.confirmacaoDePresencaSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível confirmar presença');
  }
}

export function* downloadImagensAsZip({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `documentos/download-images`,
      payload,
      headersParams
    );

    toastr.success(data.message);

    yield put(DocumentoActions.downloadImagesAsZipSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível agendar o download de imagens');
  }
}

export function* advancedSearch(action) {
  const organization = yield select(getOrganization);

  try {
    const { q, params } = action.payload;

    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: {
        ...params,
        page: !params.page || params.page === 0 ? 1 : params.page,
      },
    };

    const { data } = yield call(
      api.post,
      `/documentos/search/advanced`,
      { q },
      headersParams
    );

    yield put(DocumentoActions.advancedSearchResultSuccess(data));
  } catch (error) {
    console.error(error);
    toastr.error('Ops', 'Não foi possível pesquisar os documentos.');
  }
}

export function* removeImagem(action) {
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { selectedDoc, image } = action.payload;

    yield call(
      api.delete,
      `/documento/${selectedDoc._id}/image/${image?._id}`,
      headerParams
    );

    yield put(DocumentoActions.removeImagemSuccess());
  } catch (error) {
    toastr.error('Ops', 'Não foi excluir a imagem do documento.');
  }
}

export function* updateImagensV2(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const payload = action.payload.upload;

    let formData = new FormData();
    formData.append(`idDoc`, payload.idDoc);
    let id = 1;
    for (const file of payload.imagem) {
      formData.append(`file_${id}`, file.imagem);
      if (file?.itemchecklist)
        formData.append(`itemchecklist_${id}`, file.itemchecklist);
      formData.append(`imagem_${id}`, file.tipoImagem);
      id += 1;
    }
    const { data } = yield call(
      api.post,
      `/documento/upload/v2`,
      formData,
      headersParams
    );

    // yield put(DocumentoActions.updateImagensSuccess(data));

    const docId = action.payload.upload.idDoc;

    yield put(DocumentoActions.getDocumentoRequest(docId));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível atualizar as imagens do documento.');
  }
}

export function* updateImagens(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `/documento/upload`,
      action.payload,
      headersParams
    );

    // yield put(DocumentoActions.updateImagensSuccess(data));

    const docId = action.payload.upload.idDoc;

    yield put(DocumentoActions.getDocumentoRequest(docId));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível atualizar as imagens do documento.');
  }
}

export function* mergeImagens(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const response = yield call(
      api.post,
      `documento/merge`,
      action.payload,
      headersParams
    );

    if (response && response?.data?.error) throw new Error(response.message);

    yield put(DocumentoActions.createMergeSuccess(response));

    toastr.success('', 'Merge realizado com sucesso');
  } catch (error) {
    toastr.error('Ops', 'Não foi realizar o merge de arquivos');
  }
}

export function* uploadNewVersion(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.post,
      `/documento/upload/version`,
      action.payload,
      headersParams
    );

    const docId = action.payload.upload.idDoc;

    yield put(DocumentoActions.getDocumentoRequest(docId));
  } catch (error) {
    toastr.error(
      'Ops',
      'Não foi possível criar uma nova versão da imagem de documento.'
    );
  }
}

export function* sendEmailByApp(action) {
  const organization = yield select(getOrganization);

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const retorno = yield call(
      api.post,
      `documento/sendattachments`,
      action.payload,
      headersParams
    );

    yield put(DocumentoActions.sendEmailByAppSuccess(retorno));

    toastr.success('', 'Email enviado com sucesso.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível enviar o e-mail.');
  }
}

export function* reopen({ payload }) {
  const organization = yield select(getOrganization);
  const { documento } = payload;

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.put, `documento/${documento}/reabrir`, null, headersParams);

    yield put(DocumentoActions.reopenSuccess());
    toastr.success('Processo reaberto');
  } catch (err) {
    yield put(DocumentoActions.reopenError());
    toastr.error('Ops', 'Não foi possível reabrir o processo');
  }
}

export function* generateThumbnails({ payload }) {
  const organization = yield select(getOrganization);
  const { documentoId } = payload;

  try {
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(api.put, `documento/${documentoId}/generate-thumbnails-manual`, null, headersParams);

    yield put(DocumentoActions.generateThumbnailsSuccess());
    toastr.success('As Miniaturas serão geradas em instantes!');
  } catch (err) {
    toastr.error('Ops', 'Não foi possível gerar miniaturas');
  }
}

export function* downloadDocs({ payload }) {
  const organization = yield select(getOrganization);
  try {

    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `documento/download-docs`,
      payload,
      headersParams
    );

    toastr.success(data.message);

    yield put(DocumentoActions.downloadDocsSuccess(data));

  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível agendar o download dos documentos');
  }
}

export function* classifyDocs({ payload }) {
  const organization = yield select(getOrganization);
  try {    
    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `documento/classify-docs`,
      payload,
      headersParams
    );

    yield put(DocumentoActions.classifyDocsSuccess(payload));
    toastr.success('Os documentos serão classificados em instantes!');
  } catch (error) {
    console.log(error)
    toastr.error('Ops', 'Não foi possível classificar os documentos');
  }
}

