import api from 'services/api';
import { getToken } from 'services/auth';
import { put, call, select, all, takeLatest } from "redux-saga/effects";
import { toastr } from "react-redux-toastr";

import { Creators as EmpresaActions, Types } from 'store/ducks/empresa';

export const getOrganization = state => state.usuarios.auth.organization;

export function* getEmpresa({ payload }) {
  const organization = yield select(getOrganization);

  const { empresa, token } = payload;

  try {
    const headerParams = {
      headers: { Authorization: token, xkeyorg: organization }
    }

    const { data } = yield call(
      api.get,
      `empresa/${empresa}`,
      headerParams
    );

    yield put(EmpresaActions.getEmpresaSuccess(data))

  } catch (error) {
    toastr.error('Ops', 'Não foi possível recuperar a empresa.');
  }
}

export default all([
  takeLatest(Types.GET_REQUEST, getEmpresa)
])