import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Row, Col } from 'react-bootstrap';
import * as Icons from 'react-feather';

import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

import BPMViewer from 'components/BPMViewer';
import ToolTipComponent from 'components/Tooltip';

import { Creators as EtapasActions } from 'store/ducks/etapas';
import { Creators as DocumentoActions } from 'store/ducks/documentos';

import './styles.css';
import { FeatherIcon } from 'components/Icons';

export default function Etapas({
  onFilasChange,
  setSelectedEtapasNome,
  setCollapseBPMViewer,
  selectedEtapasNome,
  openEtapas,
  setOpenEtapas,
  selectedFilaNome,
  isToResult = false,
  multiple = false,
  functionToResult,
}) {
  const dispatch = useDispatch();

  const { showBPMNXml } = useSelector(state => state.configuracoes.configuracoes);

  const [show, setShow] = useState(false);
  const [taskDefintion, setTaskDefinition] = useState([]);
  const [endEvent, setEndEvent] = useState(false);
  const [SLA, setSLA] = useState([]);

  const {
    xml,
    loading: etapasLoading,
    count,
    idFila: fila,
  } = useSelector((state) => state.etapas);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  const { limit = 10, sort = 'documentoAnalise.0.indexadores.0.valor' } =
    useSelector((state) => state.documentos);

  const refresh = () => {
    dispatch(EtapasActions.listRequest(fila, empresas));

    dispatch(
      DocumentoActions.listRequestByEtapas({
        fila,
        etapas: null,
        empresas,
        endEvent,
        sla: SLA,
        limit,
        page: 1,
        sort,
        fields:
          'documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla',
      })
    );
  }

  const handleSearch = () => {
    if (isToResult) functionToResult({}, taskDefintion || null);
    else
      dispatch(
        DocumentoActions.listRequestByEtapas({
          etapas: taskDefintion || fila,
          empresas,
          endEvent,
          sla: SLA,
          limit,
          page: 1,
          sort,
          fields:
            'documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla',
        })
      );

    if (!showBPMNXml) {
      setCollapseBPMViewer(true);
    }


    setShow(false);
  };

  useEffect(() => {
    //etapasLoading
    if (openEtapas) {
      setShow(true);
    }
  }, [etapasLoading, openEtapas]);

  useEffect(() => {
    setTimeout(() => {
      //colapsar apos 1 segundo
      if (!showBPMNXml) {
        setCollapseBPMViewer(true);
      }
    }, 1000);
  }, [xml]);

  return (
    <>
      <Row>
        {etapasLoading ? (
          <>
            <Col md={12}>
              <Skeleton width="100%" height={300} />
            </Col>
          </>
        ) : (
          <>
            {xml && selectedFilaNome && (
              <>
                <div className='xml-buttons'>
                  <ToolTipComponent title="Atualizar análises" placement="top">
                    <button type="button">
                      <FeatherIcon fontSize="medium" icon={Icons.RefreshCw} onClick={(e) => refresh()} disabled={!taskDefintion} />
                    </button>
                  </ToolTipComponent>
                </div>
                <div className="bpmviewer">
                  <BPMViewer
                    xml={xml}
                    count={count}
                    setTaskDefinition={setTaskDefinition}
                    setEndEvent={setEndEvent}
                    fila={fila}
                    multiple={multiple}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Row>
      <Row>
        <Col md={12}>
          <div className="button-container">
            {xml && selectedFilaNome && (
              <Button
                variant="contained"
                color="secondary"
                style={{ backgroundColor: '#589667' }}
                disabled={!taskDefintion}
                onClick={(e) => handleSearch()}>
                Buscar
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
}
