import styled from 'styled-components';

export const ContainerMessage = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    font-size: 75px;
    margin-bottom: 30px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const ContainerMessageCount = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
height: 90vh;
`;