import React from "react";

import {
  Container,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "./styles/TasksActionMenu";

import { useSelector } from "react-redux";

import { CheckCircleIcon } from "components/Icons";

function SearchResultActionMenu({
  anchor,
  handleClose,
  openIndexador,
}) {
  const { etapas } = useSelector((state) => state.documentos);

  return (
    <>
      <Container
        onClose={handleClose}
        open={!!anchor}
        elevation={10}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        anchorEl={anchor}
      >
        <MenuItem disabled={!etapas} onClick={() => openIndexador()}>
          <ListItemIcon>
            <CheckCircleIcon />
          </ListItemIcon>
          <ListItemText>Avançar para próxima etapa</ListItemText>
        </MenuItem>
      </Container>
    </>
  );
}

export default SearchResultActionMenu;
