import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    margin-bottom: 15px;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;

  input {
    width: 100%;
  }
`;

export const ConectorOnboardingFields = styled.div`
  with: 100%;
  padding: 8px 10px;
  border: 1px solid #e8e8f7;
  border-radius: 5px;
  font-size: 0.875rem;
  color: #4d577d;
`
