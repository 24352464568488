import { call, put } from "redux-saga/effects";

import { toastr } from "react-redux-toastr";
import api from "../../services/api";
import { getPublicIP } from "../../services/ip";
import { getToken } from "../../services/auth";

import { Creators as TypeDocActions } from "../ducks/tiposdocumento";
import { Creators as AuditoriaActions } from "../ducks/auditoria";

import { select } from "redux-saga/effects";

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;

export function* createAuditoria(dadosAuditoria, token) {
  try {
    const publicIp = yield getPublicIP();

    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken() || token, xkeyorg: organization },
    };

    // /**
    //  * Captura informações do IP do usuário que está enviando a ação
    //  */

    const userId = yield select(getUserId);

    /**
     * Caso venha de ações dentro das telas, captura o valor de payload
     */
    if (dadosAuditoria.payload) {
      const dadosAuditoriaIp = {
        ...dadosAuditoria.payload,

        usuario: userId,
        ip: publicIp,
        data: new Date()
      };

      const { data } = yield call(
        api.post,
        `auditoria`,
        dadosAuditoriaIp,
        headerParams
      );

      // /**
      //  * Envia as informações para o endpoint de Auditoria
      //  */

      yield put(TypeDocActions.createSuccess(data));
    } else {
      /**
       * Caso não, usa os dados enviados como dadosAuditoria mesmo
       */
      const dadosAuditoriaIp = {
        ...dadosAuditoria,

        usuario: userId,
        ip: publicIp,
        data: new Date(),
      };

      const { data } = yield call(
        api.post,
        `auditoria`,
        dadosAuditoriaIp,
        headerParams
      );

      /**
       * Envia as informações para o endpoint de Auditoria
       */

      yield put(TypeDocActions.createSuccess(data));
    }
  } catch (error) { }
}

export function* listAuditoria({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: payload
    };

    const { data } = yield call(
      api.get,
      `auditoria`,
      headerParams
    );

    yield put(AuditoriaActions.listSuccess(data.docs, data.total));
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível listar os itens de Auditoria do Usuário."
    );
  }
}

export function* reportAuditoria({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
      params: {
        ...payload,
        limit: Number.MAX_SAFE_INTEGER
      }
    };

    const { data } = yield call(
      api.get,
      `auditoria`,
      headerParams
    );

    yield put(AuditoriaActions.reportSuccess(data.docs));
  } catch (error) {
    toastr.error("Ops", "Não foi possível gerar o relatorio das auditorias.");
  }
}

export function* listAuditoriaDocumentoWF({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/documentos/${payload}/wf/historico`,
      headersParams
    );

    yield put(AuditoriaActions.listDocumentoWFSuccess(data));
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível buscar os dados de histórico do documento."
    );
  }
}

export function* listAuditoriaDocumento({ payload }) {
  try {
    const organization = yield select(getOrganization);

    const headersParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.get,
      `/documentos/${payload.idDocumento}/historico`,
      headersParams
    );

    yield put(AuditoriaActions.listDocumentoSuccess(data));
  } catch (error) {
    toastr.error(
      "Ops",
      "Não foi possível buscar os dados de histórico do documento."
    );
  }
}
