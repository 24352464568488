import React, { useRef, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Creators as DocumentosActions } from "store/ducks/documentos";
import { Col, Row } from "react-bootstrap";
import { IndexadorComponent } from "components/IndexadorComponent";
import _ from "@lodash";
import { set } from "draft-js/lib/DefaultDraftBlockRenderMap";

export default function IndexadoresBulk({
  documentos,
  indexadores,
  setIndexadores,
  selecionadas = [],
}) {
  const dispatch = useDispatch();

  const dispatchDebounce = useRef(_.debounce(dispatch, 500)).current;
  const [currentEtapa, setCurrentEtapa] = useState(null);
  const [documento, setDocumento] = useState(documentos[0]);

  const { documentoBulkMove: documentoData, loadingBulkDoc } = useSelector((state) => state.documentos);

  // const documento = ""

  // const selecionadaBase = selecionadas.length > 0 ? selecionadas[0] : null;
  // const documento = documentos.find((doc) => (doc.id = selecionadaBase));
  useEffect(() => {
    documentos.length > 0 && setCurrentEtapa(documentos[0].taskDefinitionKey);
  }, [documentos]);

  useEffect(() => {
    if (documento) {
      dispatch(DocumentosActions.getInfoFromOneDocumentRequest(documento.documento));
    }
  }, [documento]);

  const indexadoresObrigatorios = useMemo(() => {
    return currentEtapa
      ? documentoData?.indexadores?.filter(
        (indexador) =>
          indexador.obrigatorioEtapa &&
          indexador.obrigatorioEtapa.includes(currentEtapa)
      )
      : null;
  }, [documentoData]);

  useEffect(() => {
    if (indexadoresObrigatorios) setIndexadores(indexadoresObrigatorios);
  }, [indexadoresObrigatorios]);

  const hasIndexadoresObrigatorios =
    Array.isArray(indexadores) && indexadores.length > 0;

  console.log(" indexadoresObrigatorios ", indexadoresObrigatorios);
  console.log(" currentEtapa ", currentEtapa);

  function handleChangeIndexadorWithLodash(e) {
    dispatchDebounce(
      DocumentosActions.metadadosRequest(
        documento.empresa._id,
        documento.modalidade._id,
        documento.tipoDocumento._id,
        e.target.name,
        e.target.value
      )
    );
    handleChangeIndexador(e);
  }

  function handleChangeIndexador({ target }) {
    const { name, value } = target;
    console.log("name ", name);
    console.log("value ", value);
    const newIndexadores = indexadores.map((indexador) => ({
      ...indexador,
      valor: name === indexador.nome ? value : indexador.valor,
    }));

    setIndexadores(newIndexadores);
  }

  return (
    <>
      <Row>
        <Col>
          {!hasIndexadoresObrigatorios && (
            <h6 className="text-center p-4">
              {loadingBulkDoc ? "Carregando..." : "Sem indexadores obrigatórios na etapa"}
            </h6>
          )}
          {!!hasIndexadoresObrigatorios &&
            indexadores.map((indexador) => (
              <Row key={indexador.nome} className="indexadores-form p-1">
                <Col md={12}>
                  <IndexadorComponent
                    indexador={indexador}
                    handleChangeIndexador={handleChangeIndexador}
                  />
                </Col>
              </Row>
            ))}
        </Col>
      </Row>
    </>
  );
}
