import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import { Container } from "./styles/ActionMenu";
import { InfoIcon, SendIcon, EditIcon, AddIcon, FaHistory, RemoveCircleIcon, PreviewIcon, ImageIcon } from "components/Icons";

function ActionMenu({
  anchor,
  handleClose,
  handleOpenDocSign,
  handleOpenChangeType,
  handleOpenImageDetail,
  handleOpenRemoveImage,
  handleOpenVersionsModal,
  handleOpenNewVersionModal,
  handleClassification,
}) {
  return (
    <Container
      id="menulist"
      open={!!anchor}
      anchorEl={anchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List component="nav" aria-label="menu de ações de imagens">
        <ListItem button onClick={handleOpenImageDetail}>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="Detalhes" />
        </ListItem>

        <ListItem button onClick={handleOpenVersionsModal}>
          <ListItemIcon>
            <FaHistory />
          </ListItemIcon>
          <ListItemText primary="Versões anteriores" />
        </ListItem>

        <ListItem button onClick={handleOpenChangeType}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Alterar tipo de imagem" />
        </ListItem>


        <ListItem button onClick={handleClassification}>
          <ListItemIcon>
            <ImageIcon />
          </ListItemIcon>
          <ListItemText primary="Classificar automaticamente" />
        </ListItem>

        <ListItem button onClick={handleOpenNewVersionModal}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Nova versão" />
        </ListItem>

        <Divider />

        <ListItem button onClick={handleOpenDocSign}>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="Assinatura eletrônica" />
        </ListItem>

        <Divider />

        <ListItem button onClick={handleOpenRemoveImage}>
          <ListItemIcon>
            <RemoveCircleIcon />
          </ListItemIcon>
          <ListItemText primary="Excluir imagem" />
        </ListItem>

      </List>
    </Container>
  );
}

export default ActionMenu;
