import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toastr } from "react-redux-toastr";
import { useDropzone } from "react-dropzone";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Typography from "@material-ui/core/Typography";
import ToolTipComponent from "components/Tooltip";
import Thumbnail from "components/Thumbnail";

import { Col, Row } from "react-bootstrap";

import { Creators as TiposImagemActions } from "../../store/ducks/tipoimagem";
import { Creators as TempStorageActions } from "../../store/ducks/tempstorage";

import "./styles.css";
import { DeleteIcon } from "components/Icons";
import { BarLoader } from "react-spinners";

export default function ModalUploadImagensPart({
  selectedDoc,
  showModal,
  handleStay,
  setShowModal,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDoc.idTipoDocumento && selectedDoc.idTipoDocumento._id) {
      dispatch(
        TiposImagemActions.listByTipoDocumentoRequest(
          selectedDoc.idTipoDocumento._id
        )
      );
    }
  }, [dispatch, selectedDoc]);

  const tiposimagem = useSelector((state) => state.tipoImagem.list);

  const sendClicked = useRef(false);

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  const { loading } = useSelector((state) => state.documentos);
  const {
    loading: load,
    loadingStorage,
    storageId,
    countRequest,
    countSuccess,
  } = useSelector((state) => state.tempstorage);

  const [filaUpload, setFilaUpload] = useState([]);

  const partialSuccess = useRef(false);
  const setPartialSuccess = (value) => partialSuccess.current = value;

  console.log("filaUpload ", filaUpload);

  const verifySuccess = !!storageId && countSuccess > 0 && countSuccess === filaUpload.length;

  console.log("partialSuccess ", partialSuccess.current);

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  //#region submit
  const generateObjectId = (
    m = Math,
    d = Date,
    h = 16,
    s = (s) => m.floor(s).toString(h)
  ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));

  const handleSubmit = async () => {
    const imgs = [];    

    let validator = 0;

    setPartialSuccess(false);

    // Validar tipo de imagem preenchido
    await asyncForEach(filaUpload, async (file, index) => {
      if (!file.tipoImagem) {
        toastr.error(
          "Ops",
          "Todas as imagens devem possuir um Tipo de Imagem."
        );

        validator++;

        return;
      }

      imgs.push({
        imagem: file,
        tipoImagem: file.tipoImagem,
        tipoFile: file.type,
        fileName: file.name,
        storageId: file.storageId,
      });
    });

    if (validator === 0) {
      sendClicked.current = true;
      processFilaRecursive(filaUpload);

      setPartialSuccess(true);
    }
  };

  const processFilaRecursive = async (fila) => {
    if (fila.length === 0) return;

    const imagem = fila[0];

    const { size, storageId, name, tipoImagem } = imagem;

    const chunkSize = 1024 * 1024 * 10;

    const totalParts = Math.ceil(size / chunkSize);

    let start = 0;
    dispatch(TempStorageActions.clearRequest());
    for (let part = 1; part <= totalParts; part++) {
      let end = Math.min(start + chunkSize, imagem.size);
      if (part === totalParts) {
        end = imagem.size;
      }
      const chunk = imagem.slice(start, end);
      const type = imagem.name.split(".").at(-1);
      const documentId = selectedDoc._id;

      const formData = new FormData();
      formData.append("part", part);
      formData.append("filename", name);
      formData.append("size", chunk.size);
      formData.append("idDoc", documentId);
      formData.append("storageId", storageId);
      formData.append("tipoImagem", tipoImagem);
      formData.append("totalParts", totalParts);
      formData.append(`file_${storageId}`, chunk);
      formData.append("type", type.toLowerCase());
      formData.append("contentType", type.toLowerCase());
      start = end;
      dispatch(TempStorageActions.newPartRequest(formData));
    }
    processFilaRecursive(fila.slice(1));
  };

  useEffect(() => {
    dispatch(TempStorageActions.clearRequest());
  }, [showModal, dispatch]);

  useEffect(() => {
    const processFiles = async () => {
      if (partialSuccess.current) {
        for (const file of filaUpload) {
          await delay(750);
          dispatch(TempStorageActions.newStorageRequest({
            storageId: file.storageId,
            idDoc: selectedDoc._id,
            tipoImagem: file.tipoImagem,
            contentType: file.type,
            type: file.path?.split(".").pop(),
          }));
        }
        dispatch(TempStorageActions.clearRequest());
        setFilaUpload([]);
        setShowModal(false);
      }
    };
    processFiles();
  }, [dispatch, partialSuccess.current, verifySuccess, countSuccess]);
  //#endregion

  useEffect(() => {
    if (!loading && sendClicked.current === true) {
      handleStay();
      sendClicked.current = false;
    }
  }, [loading]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setFilaUpload(
        acceptedFiles.map((file) => {
          file["storageId"] = generateObjectId();
          return file;
        })
      );
    }
  }, [acceptedFiles]);

  const handleExcludeImage = (image) => {
    setFilaUpload(filaUpload.filter((item) => item !== image));
  };

  const handleTipoImagem = (value, image, index) => {
    let newFilesToUpload;
    if (value) {
      newFilesToUpload = [...filaUpload];
      newFilesToUpload[index].tipoImagem = value._id;
    } else {
      newFilesToUpload = [...filaUpload];
      newFilesToUpload[index].tipoImagem = null;
    }
    setFilaUpload(newFilesToUpload);
  };

  return (
    <Modal show={showModal} onHide={handleStay} centered size="xl">
      <Modal.Header>
        <Modal.Title>Upload de Imagens</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className="container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input disabled={load} {...getInputProps()} />
          </div>
          <aside>
            <div className="dropzone-list">
              <List>
                {filaUpload &&
                  filaUpload.map((image, index) => (
                    <>
                      <Row>
                        <ListItem key={index}>
                          <Col md={2}>
                            <Thumbnail file={image} />
                          </Col>
                          <Col md={9}>
                            <Typography noWrap>{image.path}</Typography>
                            <Autocomplete
                              id="combo-box-filas"
                              getOptionLabel={(option) => option.nome}
                              options={tiposimagem.sort(
                                (a, b) => -b.nome.localeCompare(a.nome)
                              )}
                              noOptionsText="Não há tipos de imagem disponíveis"
                              getOptionSelected={(option, value) =>
                                option._id === value._id
                              }
                              onChange={(e, value) =>
                                handleTipoImagem(value, image, index)
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Selecione o tipo de imagem"
                                  variant="outlined"
                                />
                              )}
                              size="small"
                            />
                          </Col>

                          <Col md={1}>
                            <ToolTipComponent
                              title="Excluir Imagem Selecionada"
                              placement="right"
                            >
                              <ListItemIcon
                                onClick={() => handleExcludeImage(image)}
                              >
                                <DeleteIcon />
                              </ListItemIcon>
                            </ToolTipComponent>
                          </Col>
                        </ListItem>
                      </Row>
                    </>
                  ))}
              </List>
            </div>
          </aside>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <div className="progress-bar">
          <BarLoader
            color="#6259ca"
            height={8}
            loading={load}
            speedMultiplier={1}
            width={500}
          />
        </div>
        <div className="white-loading">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={load || loading}
          >
            Enviar
            {!!loading && <CircularProgress size={20} />}
          </Button>
        </div>

        <div>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleStay}
            disabled={load || loading}
          >
            Voltar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
