import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import api from '../../services/api';
import { getToken } from '../../services/auth';

import { Creators as AnaliseActions } from '../ducks/analise';
import { Creators as DocumentosActions } from '../ducks/documentos';
import { Creators as EtapasActions } from "../ducks/etapas";
import { ACTIONS as PETICIONAMENTO_ACTIONS } from '../ducks/peticionamento';

import Utils from 'utils/utils';
import documentTemplateIndexerBuilder from 'utils/document-template-indexer-builder';
import { listDocumentosByEtapas } from './documentos';
import { listEtapas } from './etapas';
import { useDispatch } from 'react-redux';

export const getOrganization = (state) => state.usuarios.auth.organization;
export const getUserId = (state) => state.usuarios.auth.id;
export const getCompaniesRules = (state) => state.empresas.regras;
export const getUserEmail = (state) => state.usuarios.auth.email;
export const getUserEmpresa = (state) => state.usuarios.auth.empresa;
export const getDocument = (state) => state.documentos;

function handleEtapa(name) {
  return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function* getAnalise({ payload }) {
  const { _id, taskId, processInstanceId, type, etapa, fila, fluxo } = payload;
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: {
        Authorization: getToken(),
        xkeyorg: organization,
        etapa: handleEtapa(etapa),
        fila: handleEtapa(fila),
        fluxo: handleEtapa(fluxo),
      },
    };

    const { data } = yield call(
      api.get,
      `analise/docs/etapa/${_id ? `${_id}/` : ''
      }${taskId}/${processInstanceId}`,
      headerParams
    );

    yield put(AnaliseActions.getSuccess(data));

    if (data.locked) {
      toastr.error(
        'Analise em andamento',
        'Outro analista já está atuando nessa análise.'
      );

      return;
    }

    if (type === 'oficio') {
      yield put(
        push({
          pathname: '/operacao/analise/oficio',
          state: { documento: data, taskId },
        })
      );
    } else {
      yield put(
        push({
          pathname: '/operacao/analise/',
          state: { documento: data, taskId },
        })
      );
    }
  } catch (error) {
    toastr.error('Ops', 'Não foi possível recuperar o documento.');
  }
}

export function* createAnalise(action) {
  const organization = yield select(getOrganization);
  const user = yield select(getUserId);
  // const regras = yield select(getCompaniesRules);

  /**
   * Filtra a regra cadastrada de acordo com o nome da função
   */

  // const regraPos = regras.filter(
  //   (item) => item.tipoRegra === "createAnalise" && item.fase === "Pós"
  // );

  // console.log("regraPos", regraPos);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const documento = action?.payload?.idDocumento;

    /**
     * Separação de dados para envio na Regra
     */
    // const dadosRegra = {
    //   idAnalise: action.payload._id,
    //   idEmpresa: action.payload.idEmpresa,
    //   idTipoDocumento: action.payload.idTipoDocumento,
    //   documento: action.payload.idDocumento,
    //   ultimaEtapa: action.payload.ultimaEtapa,
    //   userSaida: user,
    //   userCadastro: user,
    //   userAcao: user,
    //   tipoImagem: action.payload.imagens,
    // };

    /*
      Excluindo atributos não utilizados na chamada da criação da análise
    */

    // delete action.payload.idEmpresa;
    // delete action.payload.idTipoDocumento;
    // delete action.payload.ultimaEtapa;

    const { analise, indexadores } = action.payload;

    let body = {
      ...analise,
      userAcao: user,
    };

    console.log(action.payload);

    if (indexadores.properties.indexadores?.length > 0) {
      yield put(DocumentosActions.updateDocIndexRequest(indexadores));

      yield take(DocumentosActions.updateDocIndexSuccess);
    }

    const { data } = yield call(api.post, `analise/novo`, body, headerParams);

    yield put(AnaliseActions.createSuccess(data));
    yield put(DocumentosActions.clearByEtapas());

    if (analise.idDocumento)
      yield put(push(`/waiting-flow/${analise.idDocumento}`));
    else yield put(push('/operacao/esteira'));

    toastr.success('Analise salva com sucesso.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível salvar a análise.');
  }
}

export function* generatePDF(action) {
  const organization = yield select(getOrganization);
  const document = yield select(getDocument);

  yield put({
    type: PETICIONAMENTO_ACTIONS.SET_DOCUMENT_SAVE_LOADING,
    payload: true,
  });

  try {
    const documentIndexers = documentTemplateIndexerBuilder(document);
    const variablesReplacedDocument = Utils.replaceVariables(
      action.payload.content,
      documentIndexers
    );
    const requestDTO = {
      ...action.payload,
      content: variablesReplacedDocument,
    };

    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(
      api.post,
      `analise/pdf/create`,
      requestDTO,
      headerParams
    );

    yield put(AnaliseActions.generatePDFSuccess(data));

    if (action.payload.operation === 'save') {
      toastr.success('Documento salvo com sucesso.');
    }

    if (action.payload.operation === 'print') {
      window.open(data.path);
    }

    yield put(DocumentosActions.getDocumentoRequest(document._id));
  } catch (error) {
    console.error(error);
    toastr.error('Ops', 'Não foi possível imprimir o documento.');
  } finally {
    yield put({
      type: PETICIONAMENTO_ACTIONS.SET_DOCUMENT_SAVE_LOADING,
      payload: false,
    });
  }
}

export function* sendEmail(action) {
  const organization = yield select(getOrganization);
  const userEmail = yield select(getUserEmail);

  let body = {
    destinatario: action.payload.destinatario,
    remetente: userEmail,
  };

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { data } = yield call(api.post, `analise/email`, body, headerParams);

    yield put(AnaliseActions.sendEmailSuccess(data));
  } catch (error) {
    toastr.error('Ops', 'Não foi possível enviar e-mail.');
  }
}

export function* unassign({ payload }) {
  const { taskId, idDocumento, etapa, fila, fluxo } = payload;
  const organization = yield select(getOrganization);
  const documentos = yield select(getDocument);
  const {
    limit = 10,
    sort = 'documentoAnalise.0.indexadores.0.valor',
    etapas,
    values,
  } = documentos;

  try {
    const headerParams = {
      headers: {
        Authorization: getToken(),
        xkeyorg: organization,
        documento: idDocumento,
        etapa: handleEtapa(etapa),
        fila: handleEtapa(fila),
        fluxo: handleEtapa(fluxo),
      },
    };

    const { data } = yield call(
      api.get,
      `providencias/tarefa/${taskId}/unassign`,
      headerParams
    );

    yield put(AnaliseActions.unassignSuccess(data));

    yield put(
      DocumentosActions.listRequestByEtapas({
        limit,
        sort,
        etapas,
        page: 1,
        values: values,
        sla: null,
        fields:
          'documentoAnalise.numeroCNJ,documentoAnalise._id,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla,tarefa',
      })
    );

    toastr.success('Ação concluida', 'Análise desvinculada com sucesso!');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível recuperar o documento.');
  }
}

export function* batchUnassignFromUser({ payload }) {
  const { userId } = payload;
  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.patch,
      `providencias/tarefa/user/${userId}/unassign`,
      {},
      headerParams
    );

    yield put(AnaliseActions.batchUnassignFromUserSuccess());

    toastr.success(
      'Ação concluida',
      'Desvinculação de todas as tarefas do usuário agendada.'
    );
  } catch (error) {
    toastr.error(
      'Ops',
      'Não foi possível agendar a desvinculação das tarefas do usuário.'
    );
  }
}

export function* createBulkAnalise({ payload }) {

  const organization = yield select(getOrganization);

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    const { fila, empresas } = payload;

    if (!payload?.taskAndDocs)
      return;

    yield call(api.post, `analise/bulk/move`, payload, headerParams);

    //Recarregar XML e Listagem de análises

    yield listEtapas({ payload: { fila, empresas } });

    yield put(EtapasActions.listRequest(fila, empresas));

    yield listDocumentosByEtapas({
      payload: {
        fila,
        etapas: null,
        empresas,
        endEvent: false,
        sla: [],
        limit: 10,
        sort: 'documentoAnalise.0.indexadores.0.valor',
        page: 1,
        fields:
          'documentoAnalise.indexadores,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla'
      }
    });

    yield put(AnaliseActions.createBulkSuccess());

    yield put(DocumentosActions.clearBulkDocDetailsRequest())

    toastr.success('Analises salvas com sucesso.');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível salvar as análises.');
  }
}

export function* assignTaskToUser({ payload: { taskId, userId, documentId } }) {
  const organization = yield select(getOrganization);
  const documentos = yield select(getDocument);
  const {
    limit = 10,
    sort = 'documentoAnalise.0.indexadores.0.valor',
    etapas,
    values,
  } = documentos;

  try {
    const headerParams = {
      headers: { Authorization: getToken(), xkeyorg: organization },
    };

    yield call(
      api.patch,
      `providencias/tarefa/${taskId}/assign/user/${userId}`,
      {
        documentId,
      },
      headerParams
    );

    yield put(AnaliseActions.assignTaskToUserSuccess());

    yield put(
      DocumentosActions.listRequestByEtapas({
        limit,
        sort,
        etapas,
        page: 1,
        values,
        sla: null,
        fields:
          'documentoAnalise.numeroCNJ,documentoAnalise._id,usuarioAcao,etapas,ultimaEtapa,dtUltimaEtapa,sla,tarefa',
      })
    );

    toastr.success('Ação concluida', 'Análise vinculada com sucesso!');
  } catch (error) {
    toastr.error('Ops', 'Não foi possível vincular a análise.');
  }
}
