import React, { useMemo, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Creators as TipoDocsActions } from 'store/ducks/tiposdocumento';
import { Form } from '@unform/web';
import { Col, Row } from 'react-bootstrap';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const StepTwo = ({
  changeIndexador,
  indexador,
  separadores,
  handleChangeSeparador,
  handleChangeOpcoes,
  tabela,
  setTabela,
  isImageTypeIndexor = false,
  tipoImagem = null,
  disableThumbGalery = false,
}) => {
  const dispatch = useDispatch();

  const tipoDocumento = tipoImagem?.tipoDocumento?._id || null;
  const tipoDocs = useSelector((state) => state.tipoDocumentos.list);
  const indexadores = useMemo(() => {
    if (
      tipoDocs.some(
        (tipoDocumentoItem) => tipoDocumentoItem._id === tipoDocumento
      )
    ) {
      return tipoDocs.find(
        (tipoDocumentoItem) =>
          tipoDocumentoItem._id?.toString() === tipoDocumento.toString()
      )?.indexadores;
    }
    return [];
  }, [tipoDocs, tipoDocumento]);

  const {
    access: { empresas },
  } = useSelector((state) => state.permissoes);

  useEffect(() => {
    dispatch(TipoDocsActions.listRequest({ empresas }));
  }, [dispatch, empresas]);

  const filter = createFilterOptions();

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '1em',
    flexWrap: 'wrap',
  };

  return (
    <div style={containerStyle} className="flex-container flex-start mt-1">
      <Col md="12">
        <Form>
          <Row>
            <Col md="6" className="mt-2">
              <TextField
                label="Nome"
                name="nome"
                type="text"
                value={indexador.nome || ''}
                onChange={changeIndexador}
                variant="outlined"
                size="small"
                autoComplete="off"
              />
            </Col>
            <Col md="6" className="mt-2">
              <TextField
                label="Nome OCR"
                name="nomeOcr"
                type="text"
                value={indexador.nomeOcr || ''}
                onChange={changeIndexador}
                variant="outlined"
                size="small"
                autoComplete="off"
              />
            </Col>
            <Col md="6" className="mt-2">
              <TextField
                label="Máscara"
                name="mascara"
                size="small"
                value={indexador.mascara || ''}
                onChange={changeIndexador}
                variant="outlined"
                autoComplete="off"
              />
            </Col>
            <Col md="6" className="mt-2">
              <Autocomplete
                freeSolo
                selectOnFocus
                clearOnBlur
                id="free-solo-with-text-demo"
                name="separador"
                value={indexador.separador || ''}
                onChange={handleChangeSeparador}
                options={separadores}
                disabled={!!tabela}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Separador"
                    variant="outlined"
                    name="separador"
                    error={
                      (!indexador.separador || indexador.separador === '') &&
                      indexador.tipo === 'tabela'
                    }
                    helperText={
                      (!indexador.separador || indexador.separador === '') &&
                      indexador.tipo === 'tabela' &&
                      'Separador é obrigatório para o tipo de campo tabela!'
                    }
                  />
                )}
                getOptionLabel={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  if (option && option.inputValue) {
                    return option.inputValue;
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  if (params.inputValue !== '') {
                    filtered.push({
                      inputValue: params.inputValue,
                      title: `Novo separador "${params.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                renderOption={(option) => {
                  if (typeof option === 'string') {
                    return option;
                  }
                  return option.title;
                }}
                size="small"
              />
            </Col>
            <Col md="6" className="mt-2">
              <TextField
                label="Limite de caracteres"
                name="limiteCaracteres"
                value={indexador.limiteCaracteres || ''}
                onChange={changeIndexador}
                size="small"
                variant="outlined"
                type="number"
              />
            </Col>
            {isImageTypeIndexor && (
              <Col md="6">
                <Autocomplete
                  className="mt-2"
                  size="small"
                  clearOnBlur
                  selectOnFocus
                  id="indexadorTipoDoc"
                  name="indexadorTipoDoc"
                  label="Indexador tipo documento"
                  onChange={(e, newValue) => {
                    const value = newValue
                      ? {
                        id_es: newValue.id_es,
                        nome: newValue.nome,
                      }
                      : null;
                    changeIndexador({
                      target: {
                        value: value,
                        name: 'indexadorTipoDoc',
                      },
                    });
                  }}
                  options={indexadores || []}
                  value={indexador.indexadorTipoDoc || null}
                  getOptionLabel={(option) => option.nome}
                  getOptionSelected={(option, value) =>
                    value.id_es === option.id_es
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="opcoes"
                      variant="outlined"
                      label="Sincronizar com o campo do tipo de documento"
                    />
                  )}
                />
              </Col>
            )}
          </Row>
          {indexador.tipo === 'combo' && (
            <Row>
              <Col md={12}>
                <Autocomplete
                  freeSolo
                  multiple
                  selectOnFocus
                  clearOnBlur
                  id="options-combo"
                  name="opcoes"
                  onChange={handleChangeOpcoes}
                  value={indexador.opcoes || []}
                  options={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Opções"
                      variant="outlined"
                      name="opcoes"
                      error={!indexador.opcoes || indexador.opcoes.length === 0}
                      helperText={
                        (!indexador.opcoes || indexador.opcoes.length === 0) &&
                        'Opções são obrigatórias ao informar indexador do tipo combo!'
                      }
                    />
                  )}
                  getOptionLabel={(option) =>
                    typeof option === 'string' ? option : option.inputValue
                  }
                  filterOptions={(options, params) => [
                    {
                      inputValue: params.inputValue,
                      title: `Nova opção "${params.inputValue}"`,
                    },
                  ]}
                  renderOption={(option) =>
                    typeof option === 'string' ? option : option.title
                  }
                  size="small"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador.obrigatorio}
                    onChange={changeIndexador}
                    name="obrigatorio"
                    color="primary"
                  />
                }
                label="Obrigatório na plataforma"
              />
            </Col>
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador.invisivel || false}
                    onChange={changeIndexador}
                    name="invisivel"
                    color="primary"
                  />
                }
                label="Invisível"
              />
            </Col>
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador.somenteLeitura || false}
                    onChange={changeIndexador}
                    name="somenteLeitura"
                    color="primary"
                  />
                }
                label="Somente leitura"
              />
            </Col>
            {isImageTypeIndexor && (
              <>
                <Col md="3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={indexador.exibirThumbGallery || false}
                        disabled={
                          !indexador?.exibirThumbGallery && disableThumbGalery
                        }
                        onChange={changeIndexador}
                        name="exibirThumbGallery"
                        color="primary"
                      />
                    }
                    label="Exibir na galeria de arquivos"
                  />
                </Col>
                <Col md="3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={indexador.exibirPesquisaDetalhada || false}
                        onChange={changeIndexador}
                        name="exibirPesquisaDetalhada"
                        color="primary"
                      />
                    }
                    label="Exibir na pesquisa detalhada"
                  />
                </Col>
              </>
            )}
            {!isImageTypeIndexor && (
              <Col md="3">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={indexador.indexar}
                      onChange={changeIndexador}
                      name="indexar"
                      color="primary"
                    />
                  }
                  label="Pesquisável"
                />
              </Col>
            )}
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador.buscaExata}
                    onChange={changeIndexador}
                    name="buscaExata"
                    color="primary"
                  />
                }
                label="Busca exata"
              />
            </Col>
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador.limpar}
                    onChange={changeIndexador}
                    name="limpar"
                    color="primary"
                  />
                }
                label="Limpar após interação"
              />
            </Col>
            {!isImageTypeIndexor && (
              <>
                <Col md="3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={indexador.esteira || false}
                        onChange={changeIndexador}
                        name="esteira"
                        color="primary"
                      />
                    }
                    label="Pesquisável na esteira/pesquisa detalhada"
                  />
                </Col>

                <Col md="3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={indexador.exibirPesquisaDetalhada || false}
                        onChange={changeIndexador}
                        name="exibirPesquisaDetalhada"
                        color="primary"
                      />
                    }
                    label="Exibir na pesquisa detalhada/esteira"
                  />
                </Col>

                <Col md="3">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={indexador.destacarFormalizacao || false}
                        onChange={changeIndexador}
                        name="destacarFormalizacao"
                        color="primary"
                      />
                    }
                    label="Destacar na formalização"
                  />
                </Col>
              </>
            )}
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador?.sensivel}
                    onChange={changeIndexador}
                    name="sensivel"
                    color="primary"
                  />
                }
                label="Dado pessoal"
              />
            </Col>
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={indexador.tipo !== "texto"}
                    checked={indexador?.externalID}
                    onChange={changeIndexador}
                    name="externalID"
                    color="primary"
                  />
                }
                label="Identificação externa"
              />
            </Col>
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador?.conectorOnboarding}
                    onChange={changeIndexador}
                    name="conectorOnboarding"
                    color="primary"
                  />
                }
                label="Conector onboarding"
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={indexador?.obrigatorioOnboarding}
                    onChange={changeIndexador}
                    name="obrigatorioOnboarding"
                    color="primary"
                  />
                }
                label="Obrigatório no Onboarding"
              />
            </Col>
          </Row>
        </Form>
      </Col>
    </div>
  );
};

export default StepTwo;
