import React, { useEffect } from 'react';
import logoBranco from 'assets/img/logo.png';
import logoBrancoNV from 'assets/img/logo-nv.webp';
import { useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Creators as UsuariosActions } from '../../store/ducks/usuarios';

import './styles.css';

const initialValues = {
  code: '',
};

export default function TwoFactor() {
  const containsNSSmartSuite = process.env.REACT_APP_FRONT_URL.includes('nssmartsuite');

  const dispatch = useDispatch();
  const { auth, validateCode } = useSelector(state => state.usuarios);
  const state = useSelector(state => state.usuarios);

  const { register, handleSubmit } = useForm();
  const [code, setCode] = useState(initialValues);

  const [loading, setLoading] = useState(false);
  const [validado, setValidado] = useState(0);

  const initialData = useMemo(() => {
    return { nome: auth.name, email: auth.email };
  }, [auth]);

  const handleStatus = statusMessage => {
    // toastr.error(statusMessage);
    setLoading(false);
  };

  function onChange(e) {
    const { name, value } = e.target;
    setCode({ ...code, [name]: value });
  }

  async function onSubmit(data) {
    try {
      setLoading(true);

      const twoFactorCode = code.code;

      if (twoFactorCode.length < 6) {
        handleStatus('Código de validação inválido.');
        return;
      }

      const token = localStorage.getItem('jwt');

      await dispatch(UsuariosActions.twoFactorRequest({ code: twoFactorCode, jwt: token }));

      setLoading(false);

      setTimeout(function () {
        setValidado(validado + 1);
      }, 500);
    } catch (error) {
      console.error(error);
      handleStatus('Código de validação incorreto.');
    }
  }

  useEffect(() => {
    if (validado > 0 && !validateCode) handleStatus('Código de validação incorreto.');
  }, [validado, validateCode]);

  return (
    <div id="twofactor">
      <div className="content">
        <div className="introduction">
          <div className="introduction-logo">
            {/* {containsNSSmartSuite ? (
              <img src={logoBranco} alt="Logo NewSpace" />
            ) : (
              <img src={logoBrancoNV} alt="Logo NV Security" />
            )} */}
          </div>
        </div>
        <div className="twofactor-content">
          {/* {containsNSSmartSuite ? (
            <img src={logoBranco} alt="Logo NewSpace" />
          ) : (
            <img src={logoBrancoNV} alt="Logo NV Security" />
          )} */}
          <div className="twofactor-title">
            <h5>Verificação em dois fatores</h5>
            <p></p>
            <h6>Verifique o código para autenticação enviado para</h6>
            <b>{initialData?.email}</b>
          </div>
          <div className="twofactorForm">
            <form className="twofactor-form" onSubmit={handleSubmit(onSubmit)}>
              <Col md={10}>
                <p></p>
                <div className="fw-bold text-start text-dark fs-6 mb-1 ms-1">
                  Digite o código de verificação recebido
                </div>

                <Row>
                  <Col md="12">
                    <input
                      type="text"
                      onChange={onChange}
                      ref={register}
                      name="code"
                      maxLength={6}
                      className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={loading}
                    />
                  </Col>
                  {/* <Col>
                    <input
                      type="text"
                      onChange={onChange}
                      ref={register}
                      name="code_2"
                      maxLength={1}
                      className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={loading}
                    />
                  </Col>
                  <Col>
                    <input
                      type="text"
                      onChange={onChange}
                      ref={register}
                      name="code_3"
                      maxLength={1}
                      className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={loading}
                    />
                  </Col>
                  <Col>
                    <input
                      type="text"
                      onChange={onChange}
                      ref={register}
                      name="code_4"
                      maxLength={1}
                      className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={loading}
                    />
                  </Col>
                  <Col>
                    <input
                      type="text"
                      onChange={onChange}
                      ref={register}
                      name="code_5"
                      maxLength={1}
                      className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={loading}
                    />
                  </Col>
                  <Col>
                    <input
                      type="text"
                      onChange={onChange}
                      ref={register}
                      name="code_6"
                      maxLength={1}
                      className="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2"
                      inputMode="numeric"
                      autoComplete="off"
                      disabled={loading}
                    />
                  </Col> */}
                </Row>
              </Col>

              <div className="d-flex flex-center">
                <Button variant="success" type="submit">
                  Verificar
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
